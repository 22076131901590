import React from 'react'
import InnerHTML from 'dangerously-set-html-content'

function HomeListings() {
  const html = `
    <div class="container mx-[200px] my-[200px]">
    <div id="idxStart"></div>
              <script charset="UTF-8" type="text/javascript" id="idxwidgetsrc-38390" src="//homes.selbysellssd.com/idx/widgets/38390"></script>
              <div id="idxStop"></div>
    </div>
  `

  return (
    <InnerHTML html={html} />
  )

  }

 export default HomeListings