import React, { useContext, useEffect } from "react";
import Button from "../../Button/Button";
import buyHomeImg from "../../../images/buy_home.png";
import polygon1 from "../../../images/Polygon-1.png";
import polygon2 from "../../../images/Polygon-2.png";
import polygon3 from "../../../images/Polygon-3.png";
import polygon4 from "../../../images/Polygon-4.png";
import CheckboxIcon from "../../Icons/checkbox";
import ModalContext from "../../context/ModalContext";
import { StaticImage } from "gatsby-plugin-image";

const BuyHome = () => {
  const { setModalvisible, setPopupContent } = useContext(ModalContext);
  useEffect(() => {
    const timer = setTimeout(() => {
      setModalvisible(true);
      setPopupContent("newsletter");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div className="py-120 md:pt-[132px] md:pb-[13px] sm:pt-0">
        <div className="container md:px-0">
          <div className="flex items-start md:gap-[34px] justify-between md:flex-col-reverse md:justify-start md:items-start">
            <div className="max-w-[560px] md:px-[17px] w-full lg:mr-[30px] md:max-w-[500px] md:max-w-[100%] md:w-full md:mr-[0px] md:mb-[50px]">
              <h1 className="text-d-3xl text-[#1A1A1A] font-IbarraRealNova font-600 pb-24">
                Buying a Home <span className="font-Quicksand font-500">in San Diego</span>
              </h1>
              <p className="text-d-3lg font-500 font-Quicksand pb-[24px] text-[#666666]">
                It’s easy to find a home you’ll love with The Selby Team. We have deep roots in the
                local market in “America’s Finest City” and know how to find you what you’re looking
                for when buying a home in San Diego. There are big advantages to working with
                knowledgeable{" "}
                <a
                  className="text-blue-500"
                  href="https://selbysellssd.com/san-diego-real-estate-agents/"
                >
                  real estate professionals
                </a>{" "}
                like The Selby Team:
              </p>
              <div className="font-Quicksand text-d-2lg text-[#666666] pb-[28px]">
                <div className="flex items-start mb-[8px]">
                  <p className="mt-[1px]">
                    <CheckboxIcon />
                  </p>
                  <p className="ml-[19px] font-600">
                    We know the price properties are selling at and the current market demand.
                  </p>
                </div>

                <div className="flex items-start mb-[8px]">
                  <p className="mt-[1px]">
                    <CheckboxIcon />
                  </p>
                  <p className="ml-[19px]  font-600">
                    When we determine a fair market value for your potential new home, we aren’t
                    just guessing. We know.
                  </p>
                </div>

                <div className="flex items-start mb-[8px]">
                  <p className="mt-[1px]">
                    <CheckboxIcon />
                  </p>
                  <p className="ml-[19px]  font-600">
                    We can advise you on how to handle the financial side of transactions.
                  </p>
                </div>

                <div className="flex items-start mb-[8px]">
                  <p className="mt-[1px]">
                    <CheckboxIcon />
                  </p>
                  <p className="ml-[19px]  font-600">
                    We’re experts at negotiating and getting a fair price.
                  </p>
                </div>

                <div className="flex items-start mb-[8px]">
                  <p className="mt-[1px]">
                    <CheckboxIcon />
                  </p>
                  <p className="ml-[19px]  font-600">
                    We streamline the entire process and tame the mounds of paperwork.
                  </p>
                </div>
              </div>
              <Button
                onClick={() => {
                  setModalvisible(true);
                  setPopupContent("steps");
                }}
                type="solid"
                color="white"
                customStyle="px-42 mx-24"
              >
                <p>Contact</p>
              </Button>
            </div>
            <div className="w-fit relative">
              <div className="relative">
                <StaticImage src="../../../images/buy_home.png" alt="bannerImage" />
              </div>
              {/* <img src={buyHomeImg} className="relative" alt="bannerImage" /> */}
              <div className="absolute top-0">
                <StaticImage src="../../../images/Polygon-1.png" alt="bannerImageborder" />
              </div>
              <div className="absolute bottom-0 right-0">
                <StaticImage src="../../../images/Polygon-2.png" alt="bannerImageborder" />
              </div>
              <div className="absolute top-0 right-0 top-0">
                <StaticImage src="../../../images/Polygon-3.png" alt="bannerImageborder" />
              </div>
              <div className="absolute bottom-0 left-0">
                <StaticImage src="../../../images/Polygon-4.png" alt="bannerImageborder" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BuyHome;
