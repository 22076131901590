import Buy1 from '../../images/buy1.png'
import Buy2 from '../../images/buy2.png'
import Buy3 from '../../images/buy3.png'
import Buy4 from '../../images/buy4.png'
import Buy5 from '../../images/buy5.png'
import Buy6 from '../../images/buy6.png'


export const BuyHomeData = [
    {
      img: Buy1,
      step: 'Step 1',
      card_title: 'Tell Us What You’re Looking For',
      card_title_tag:
        'Connect with a Selby real estate professional for an initial consultation to discuss your needs and priorities.',
        btn_val:  '',
        remove: 'remove'
    },
    {
      img: Buy2,
      step: 'Step 2',
      card_title: 'Nail Down the Financing',
      card_title_tag:
        'Find a mortgage company and consult with a loan officer to get pre-qualified, then pre-approved.',
        btn_val:  '',
        remove: 'remove'
    },
    {
      img: Buy3,
      step: 'Step 3',
      card_title: 'Find the Right Home',
      card_title_tag:
        'Evaluate the properties we show you based on your criteria and determine if there’s one that is the right fit for you.',
        btn_val:  '',
        remove: 'remove'
    },
    {
      img: Buy4,
      step: 'Step 4',
      card_title: 'Make an Offer ',
      card_title_tag:
        'Work closely with your Selby real estate professional to determine an offer price. Then prepare your earnest money deposit.',
        btn_val:  '',
        remove: 'remove'
    },
    {
      img: Buy5,
      step: 'Step 5',
      card_title: 'Reach an Agreement With a Seller',
      card_title_tag:
        'Put your best foot forward, negotiate terms and possible counter-offers, and reach an agreed-upon sale contract.',
        btn_val:  '',
        remove: 'remove'
    },
    {
      img: Buy6,
      step: 'Step 6',
      card_title: 'Close the Deal',
      card_title_tag:
        'Finalize the funding, review and sign documents, record the title, and get the keys to your new `home!',
        btn_val:  'Download Our Buyer’s Guide',
        remove: '',
        url: "/files/2021.08.10_Buyers Packet - The Selby Team_FF.pdf"
    },
  ]