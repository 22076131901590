import React, { FC } from "react";
import { FeaturedListingMainContainer } from "./style";
import feature1 from "../../../images/featured_listing_img.jpg";
import Button from "../../Button/Button";
import { Link } from "gatsby";
import HomeListings from "../../Listings/HomeListings";
import CustomListings from "../../Listings/CustomListings";

const Feature_listing_Data = [
  {
    img: feature1,
    card_title: "4665 35th Street",
    card_title_tag: "San Diego, CA 92116",
    price_tag: "Price",
    price_val: "$749,000",

    bed_tag: "Beds",
    bed_avali: "3",

    bath_tag: "Baths",
    bath_avali: "1",

    sqft_tag: "Sqft",
    sqft_val: "648",
  },
  {
    img: feature1,
    card_title: "4665 37th Street",
    card_title_tag: "San Diego, CA 92116",
    price_tag: "Price",
    price_val: "$749,000",

    bed_tag: "Beds",
    bed_avali: "3",

    bath_tag: "Baths",
    bath_avali: "1",

    sqft_tag: "Sqft",
    sqft_val: "648",
  },
  {
    img: feature1,
    card_title: "6755 13th Street",
    card_title_tag: "San, CZ 45565",
    price_tag: "Price",
    price_val: "$669,000",

    bed_tag: "Beds",
    bed_avali: "5",

    bath_tag: "Baths",
    bath_avali: "1",

    sqft_tag: "Sqft",
    sqft_val: "698",
  },
  {
    img: feature1,
    card_title: "4665 37th Street",
    card_title_tag: "San Diego, CA 92116",
    price_tag: "Price",
    price_val: "$749,000",

    bed_tag: "Beds",
    bed_avali: "3",

    bath_tag: "Baths",
    bath_avali: "1",

    sqft_tag: "Sqft",
    sqft_val: "648",
  },
  {
    img: feature1,
    card_title: "4665 37th Street",
    card_title_tag: "San Diego, CA 92116",
    price_tag: "Price",
    price_val: "$749,000",

    bed_tag: "Beds",
    bed_avali: "3",

    bath_tag: "Baths",
    bath_avali: "1",

    sqft_tag: "Sqft",
    sqft_val: "648",
  },
  {
    img: feature1,
    card_title: "4665 37th Street",
    card_title_tag: "San Diego, CA 92116",
    price_tag: "Price",
    price_val: "$749,000",

    bed_tag: "Beds",
    bed_avali: "3",

    bath_tag: "Baths",
    bath_avali: "1",

    sqft_tag: "Sqft",
    sqft_val: "648",
  },
  {
    img: feature1,
    card_title: "4665 37th Street",
    card_title_tag: "San Diego, CA 92116",
    price_tag: "Price",
    price_val: "$749,000",

    bed_tag: "Beds",
    bed_avali: "3",

    bath_tag: "Baths",
    bath_avali: "1",

    sqft_tag: "Sqft",
    sqft_val: "648",
  },
  {
    img: feature1,
    card_title: "7865 35th Street",
    card_title_tag: "Diego, CC 17816",
    price_tag: "Price",
    price_val: "$949,000",

    bed_tag: "Beds",
    bed_avali: "3",

    bath_tag: "Baths",
    bath_avali: "2",

    sqft_tag: "Sqft",
    sqft_val: "788",
  },
];
interface FeatureListProps {
  btn_title?: string;
  remove?: string;
  headingBold?: string;
  headingLight?: string;
  listingId?: string;
}
const FeaturedListing: FC<FeatureListProps> = ({
  headingBold,
  headingLight,
  btn_title,
  remove,
  listingId,
}) => {
  return (
    <>
      <FeaturedListingMainContainer className="py-120 sm:py-[90px]">
        <div className="container">
          <div>
            <h2 className="font-IbarraRealNova text-d-2xl font-700 text-center">
              {headingBold}
              <span className="font-Quicksand font-500"> {headingLight}</span>
            </h2>
            <div className="pt-55 flex flex-wrap gap-x-26 md:gap-x-[19px] 2xs:gap-x-[14px] gap-y-[40px] sm:px-[43px] xs:px-[16px] last:mb-[0px]">
              {/*}  {Feature_listing_Data.map((value) => {
                return (
                  <div className="featured_listing_card w-full shadow-b">
                    <img
                      src={value.img}
                      alt="feature_listing"
                      className="w-full object-cover"
                    />
                  </div>
                );
              })} */}
              {listingId ? <CustomListings listingId={listingId} /> : <HomeListings />}
            </div>
            <div id={remove} className="flex justify-center mt-[56px]">
              <Link to="https://homes.selbysellssd.com/idx/map/mapsearch">
                <Button type="solid" customStyle="px-[29px]">
                  <p>{btn_title}</p>
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </FeaturedListingMainContainer>
    </>
  );
};

export default FeaturedListing;
