import styled from 'styled-components'

const FeaturedListingMainContainer = styled.div`
  & .featured_listing_card {
    cursor: pointer;
    width: 100%;
    max-width: calc(100% / 4 - 20px);
  }

  & .featured_listing_card_descrip .feature_listing_card_info ul li {
    /* max-width: calc(100% / 4); */
  }
  @media only screen and (max-width: 991px) {
    & .featured_listing_card {
      width: 100%;
      max-width: calc(100% / 3 - 13px);
    }
  }

  @media only screen and (max-width: 767px) {
    & .featured_listing_card {
      width: 100%;
      max-width: calc(100% / 2 - 10px);
    }
  }

  @media only screen and (max-width: 575px) {
    & .featured_listing_card {
      width: 100%;
      max-width: calc(100% / 2 - 7px);
    }
  }

  @media only screen and (max-width: 479px) {
    & .featured_listing_card {
      width: 100%;
      max-width: calc(100% / 1 - 0px);
    }
  }

  & .featured_listing_card:hover .custom_hover {
    color: #fff;
    background-color: #313131;
  }

  & .featured_listing_card:hover .feature_listing_card_info ul li {
    border-color: #fff;
  }
`

export { FeaturedListingMainContainer }
