import React from "react";
import BuyHome from "../component/Buy/Buy";
import LayoutWrapper from "../component/LayoutWrapper/Index";

const Buy = () => {
  return (
      <LayoutWrapper
        title="Buy a House in San Diego | The Selby Team"
        desc="When you buy a house in San Diego, it's easy to love where you live. Search the latest listings and call today to speak with a buyer's agent at The Selby Team."
        headerLogo="../../images/Selby_logo_v2.svg"
        headerId="header_v2"
      >
        <BuyHome />
      </LayoutWrapper>
  );
};

export default Buy;
