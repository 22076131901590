import React from "react";
import InnerHTML from "dangerously-set-html-content";

function CustomListings({ listingId }) {
  const html = `
    <div class="container mx-[200px] my-[200px]">
    <div id="idxStart"></div>
      <script charset="UTF-8" type="text/javascript" id="idxwidgetsrc-${listingId}" src="//homes.selbysellssd.com/idx/widgets/${listingId}"></script>
      <div id="idxStop"></div>
    </div>
  `;

  return <InnerHTML html={html} />;
}

export default CustomListings;
