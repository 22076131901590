import React from 'react'
import BuyHome from './BuyHome/BuyHome'
import { BuyHomeData } from '../Buy/BuyData'
import FeaturedListing from '../Home//FeaturedListing/FeaturedListing'
import WhatWeDo from '../Home/WhatWeDo/WhatWeDo'
import HowToBuy from './HowToBuy/HowToBuy'
import HappyBuyers from '../Home/HappyBuyers/HappyBuyers'
import MeetFamily from '../Home/MeetFamily/MeetFamily'
import FindNeighborhood from '../Home/FindNeighborhood/FindNeighborhood'
import MoveYourFamily from '../Home/MoveYourFamily/MoveYourFamily'

const Buy = () => {
  return (
    <>
      <BuyHome />
      <HowToBuy
        getMapData={BuyHomeData}
        howToBuyHeading={
          <h2 className="font-IbarraRealNova text-d-2xl text-[#1A1A1A] font-[700] text-center sm:text-d-30">
            How to <span className="font-Quicksand font-[500]">Buy</span> a Home
          </h2>
        }
        lineHeight={82}
      />
      <div className="pt-[70px]">
        <WhatWeDo />
      </div>
      <HappyBuyers />
      <MeetFamily remove="" btn_title="Get My Estimate" />
      <div className="mt-[-120px] mb-[-56px]">
        <FeaturedListing
          btn_title="View All Listings"
          remove=""
          headingBold="Selby Team"
          headingLight="Featured Listings"
        />
  
      </div>
      <FindNeighborhood remove="remove" />
      <MoveYourFamily />
    </>
  )
}

export default Buy
