import React, { FC } from "react";
interface SelbySecretCard {
  category?: string;
  date?: string;
  description?: string;
  link?: string;
}

const SelbySecretCard: FC<SelbySecretCard> = ({ category, date, description, link }) => {
  return (
    <a href={link}>
      <div className="max-w-[600px] w-full bg-white p-[24px] rounded-[16px] shadow-b mb-[24px] md:max-w-[90%] sm:max-w-[100%]">
        <p className="text-d-2lg font-Quicksand text-[#B2B2B2] uppercase font-700 pb-[16px]">
          {category} / {date}
        </p>
        <h3 className="text-d-6lg font-700 font-IbarraRealNova">{description}</h3>
      </div>
    </a>
  );
};

export default SelbySecretCard;
