/* eslint-disable max-len */
import React, { FC } from "react";
interface CheckboxIconType {
  color?: string;
}
const CheckboxIcon: FC<CheckboxIconType> = ({ color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V15C0 15.7956 0.316071 16.5587 0.87868 17.1213C1.44129 17.6839 2.20435 18 3 18H15C15.7956 18 16.5587 17.6839 17.1213 17.1213C17.6839 16.5587 18 15.7956 18 15V3C18 2.20435 17.6839 1.44129 17.1213 0.87868C16.5587 0.316071 15.7956 0 15 0ZM13.3 6.61L8.73 12.61C8.63684 12.731 8.5172 12.8291 8.38026 12.8967C8.24332 12.9643 8.09272 12.9996 7.94 13C7.78811 13.0008 7.63802 12.967 7.50114 12.9012C7.36426 12.8353 7.24417 12.7392 7.15 12.62L4.71 9.51C4.62924 9.40626 4.5697 9.28762 4.53478 9.16087C4.49987 9.03411 4.49026 8.90172 4.50652 8.77126C4.52277 8.64079 4.56455 8.5148 4.62949 8.40049C4.69443 8.28617 4.78126 8.18576 4.885 8.105C5.09452 7.94189 5.36026 7.8687 5.62374 7.90152C5.75421 7.91777 5.8802 7.95955 5.99451 8.02449C6.10883 8.08943 6.20924 8.17626 6.29 8.28L7.92 10.36L11.7 5.36C11.7801 5.25494 11.8801 5.16669 11.9943 5.10029C12.1085 5.03388 12.2347 4.99062 12.3657 4.97298C12.4966 4.95534 12.6297 4.96365 12.7574 4.99746C12.8851 5.03126 13.0049 5.08989 13.11 5.17C13.2151 5.25011 13.3033 5.35012 13.3697 5.46433C13.4361 5.57855 13.4794 5.70472 13.497 5.83565C13.5147 5.96658 13.5063 6.0997 13.4725 6.22742C13.4387 6.35514 13.3801 6.47494 13.3 6.58V6.61Z"
        fill={color || "black"}
      />
    </svg>
  );
};

export default CheckboxIcon;
